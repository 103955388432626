<div class="flex flex-col relative w-full h-full overflow-hidden">
    <div class="flex gap-2 items-baseline mb-2">
        <span class="text-xl">
            <span>Requests</span>
            @if (referralCode(); as referralCode) {
                <span class="ml-5">By: {{ referralUsername() }}</span>
                <span class="ml-5">Code: {{ referralCode }}</span>
                <button class="ml-5" type="button" mat-stroked-button (click)="clearFilter()">
                    Clear filter
                </button>
            }
        </span>

        <span class="flex-1"></span>

        @if (adminRole() !== 'COMPLIANCE') {
            <ng-container [formGroup]="rangePickerCSVForm">
                <mat-form-field>
                    <mat-label>Enter a date range for CSV</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input matStartDate formControlName="from" placeholder="From date">
                        <input matEndDate formControlName="to" placeholder="To date">
                    </mat-date-range-input>
                    @if (rangePickerCSVForm.controls.from.value || rangePickerCSVForm.controls.to.value) {
                        <button mat-icon-button matIconSuffix
                                (click)="rangePickerCSVForm.controls.from.reset(); rangePickerCSVForm.controls.to.reset();">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>

                    @if (rangePickerCSVForm.controls.from.hasError('matStartDateInvalid') || rangePickerCSVForm.controls.from.hasError('matDatepickerParse')) {
                        <mat-error>Invalid from date</mat-error>
                    } @else if (rangePickerCSVForm.controls.to.hasError('matEndDateInvalid') || rangePickerCSVForm.controls.to.hasError('matDatepickerParse')) {
                        <mat-error>Invalid to date</mat-error>
                    }
                </mat-form-field>
            </ng-container>

            <button [disabled]="rangePickerCSVForm.invalid" mat-icon-button (click)="downloadCSV()">
                <mat-icon>sim_card_download</mat-icon>
            </button>
        }

        <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>settings</mat-icon>
        </button>

        <mat-menu #menu="matMenu" [hasBackdrop]="true">
            <div class="max-h-[200px] overflow-auto" cdkScrollable>
                <div cdkDropList class="menu-list bg-white dark:bg-[#424242]" (cdkDropListDropped)="drop($event)">
                    @for (displayedColumn of menuDisplayedColumns; track displayedColumn) {
                        <div class="menu-item bg-white dark:bg-[#424242]" cdkDrag>
                            <mat-icon class="cursor-move" cdkDragHandle>drag_handle</mat-icon>
                            <mat-checkbox color="primary" (click)="$event.stopPropagation()"
                                          [ngModel]="!filteredDisplayedColumns.includes(displayedColumn)"
                                          (change)="handlerChangeDisplayColumnCheckbox($event, displayedColumn)"></mat-checkbox>
                            {{ displayedColumn }}
                        </div>
                    }
                </div>
            </div>
        </mat-menu>
    </div>

    <div class="flex gap-2 overflow-y-auto mb-2">
        @let filterStatusValue = filterStatus();
        <mat-card (click)="filterStatus.set(null)" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': !filterStatusValue
        }" appearance="outlined">
            <mat-card-content>All</mat-card-content>
        </mat-card>

        <mat-card (click)="filterStatus.set('LEAD')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'LEAD'
        }" appearance="outlined">
            <mat-card-content>DRAFT</mat-card-content>
        </mat-card>

        <mat-card (click)="filterStatus.set('NEW')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'NEW'
        }" appearance="outlined">
            <mat-card-content>SUBMITTED</mat-card-content>
        </mat-card>

        <mat-card (click)="filterStatus.set('OFFER')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'OFFER'
        }" appearance="outlined">
            <mat-card-content>OFFER</mat-card-content>
        </mat-card>

        <mat-card (click)="filterStatus.set('INITIAL_INVOICE')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'INITIAL_INVOICE'
        }" appearance="outlined">
            <mat-card-content>INVOICE</mat-card-content>
        </mat-card>

        <mat-card (click)="filterStatus.set('CUSTOMER')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'CUSTOMER'
        }" appearance="outlined">
            <mat-card-content>CUSTOMER</mat-card-content>
        </mat-card>

        <mat-card (click)="filterStatus.set('DONE')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'DONE'
        }" appearance="outlined">
            <mat-card-content>DONE</mat-card-content>
        </mat-card>

        <span class="flex-1"></span>

        <mat-card (click)="filterStatus.set('OFFER_EXPIRED')" class="hover:!bg-gray-200 cursor-pointer" [ngClass]="{
            '!bg-gray-200': filterStatusValue === 'OFFER_EXPIRED'
        }" appearance="outlined">
            <mat-card-content>OFFER_EXPIRED</mat-card-content>
        </mat-card>
    </div>

    <div class="project-table flex-1 overflow-auto rounded-lg border border-solid border-gray-200 shadow-base relative mb-10">
        <table [dataSource]="dataSource" class="!whitespace-nowrap"
               [ngClass]="{'!h-full': !dataSource.data.length|| !dataSource.filteredData.length}"
               mat-table matSort (matSortChange)="handleSort($event)">
            <ng-container matColumnDef="userId">
                <th *matHeaderCellDef mat-header-cell mat-sort-header class="!pl-5">User Id</th>
                <td *matCellDef="let element" mat-cell [matTooltip]="element.userId" class="!pl-5"
                    [matTooltipShowDelay]="500">
                    {{ (element.userId | cropWithDotsCenter) || '---' }}
                    @if (element.userId) {
                        <app-copy-button [value]="element.userId" size="small"></app-copy-button>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="id">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Case Id</th>
                <td *matCellDef="let element" mat-cell [matTooltip]="element.id" [matTooltipShowDelay]="500">
                    {{ (element.id | cropWithDotsCenter) || '---' }}
                    @if (element.id) {
                        <app-copy-button [value]="element.id" size="small"></app-copy-button>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="telegramUsername" sticky>
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Telegram Username</th>
                <td *matCellDef="let element" mat-cell>
                    <app-telegram-user-link (click)="$event.stopPropagation()"
                                            [telegramUsername]="element.telegramUsername"></app-telegram-user-link>
                </td>
            </ng-container>

            <ng-container matColumnDef="country">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Country</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.country ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="victimType">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Victim`s Type</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.victimType ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="scamTypeId">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Scam Type</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.scamTypeId ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="telegramUserId">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Telegram ID</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.telegramUserId ?? '---' }}
                    @if (element.telegramUserId) {
                        <app-copy-button [value]="element.telegramUserId" size="small"></app-copy-button>
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="submittedAt">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Submission Date</th>
                <td *matCellDef="let element" mat-cell>
                    {{ (element.submittedAt | date: 'hh:mm a, dd/MM/yyyy') || '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="updatedAt">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Case last update</th>
                <td *matCellDef="let element" mat-cell>
                    {{ (element.updatedAt | date: 'hh:mm a, dd/MM/yyyy') || '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="phone">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Phone</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.phone ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Email</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.email ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="kyc">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>KYC</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.kyc ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="caseAmount">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Case Amount</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.estimatedLosesString ?? '---' }}
                    {{ element.currency ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="leadStatus">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Case Status</th>
                <td *matCellDef="let element" mat-cell>
                    @if (element.leadStatus === 'SUBMITTED') {
                        {{ element.incidentStatus }}
                    } @else {
                        {{ element.leadStatus }}
                    }
                </td>
            </ng-container>

            <ng-container matColumnDef="paymentDue">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Payment Due</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.paymentDue ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="totalCost">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Total Paid</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.totalCost ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="numberOfInvoices">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Number Of Invoices</th>
                <td *matCellDef="let element" mat-cell>
                    {{ element.numberOfInvoices ?? '---' }}
                </td>
            </ng-container>

            <ng-container matColumnDef="referralPermission">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Referral Permission</th>
                <td *matCellDef="let element" mat-cell (click)="$event.stopPropagation()">
                    <mat-slide-toggle [(ngModel)]="element.canCreateReferral"
                                      (change)="canCreateReferrals(element, $event)"></mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th *matHeaderCellDef mat-header-cell></th>
                <td *matCellDef="let element" mat-cell (click)="$event.stopPropagation()">
                    @switch (element.incidentStatus) {
                        @case ('NEW') {
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button type="button" mat-menu-item (click)="addOffer(element)">Add offer</button>

                                <button type="button" mat-menu-item (click)="cancelCase(element)">Cancel Case</button>
                            </mat-menu>
                        }
                        @case ('OFFER_EXPIRED') {
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button type="button" mat-menu-item (click)="addOffer(element)">Add offer</button>

                                <button type="button" mat-menu-item (click)="cancelCase(element)">Cancel Case</button>
                            </mat-menu>
                        }
                        @case ('REQUEST_OFFER') {
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button type="button" mat-menu-item (click)="addOffer(element)">Add offer</button>

                                <button type="button" mat-menu-item (click)="cancelCase(element)">Cancel Case</button>
                            </mat-menu>
                        }
                        @case ('CUSTOMER') {
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button type="button" mat-menu-item (click)="addOffer(element)">Add offer</button>
                                <button type="button" mat-menu-item (click)="toSuccess(element)">To Success</button>
                                <button type="button" mat-menu-item (click)="upgradeOffer(element)">Upgrade offer
                                </button>
                            </mat-menu>
                        }
                        @case ('INITIAL_INVOICE') {
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button type="button" mat-menu-item (click)="upgradeOffer(element)">Upgrade offer
                                </button>
                            </mat-menu>
                        }
                        @case ('SUCCESS') {
                            <button mat-icon-button [matMenuTriggerFor]="menu">
                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button type="button" mat-menu-item (click)="toDone(element)">To Done</button>
                            </mat-menu>
                        }
                    }
                </td>
            </ng-container>

            <tr *matNoDataRow class="mat-mdc-row mdc-data-table__row !h-[300px]">
                <td [attr.colspan]="displayedColumns.length" class="mat-mdc-cell mdc-data-table__cell"
                    style="text-align: center">
                    <div class="flex justify-center items-center p-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
                        <img alt="data-empty" height="146" [priority]="true"
                             ngSrc="assets/icons/custom/data-empty.svg"
                             width="227">
                    </div>
                </td>
            </tr>

            <tr *matHeaderRowDef="displayedColumns; sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;"
                class="cursor-pointer hover:!bg-gray-100 dark:hover:!bg-[#5f5f5f]" mat-row
                [routerLink]="['/case', row.id]"
                [ngClass]="{
                    'not-viewed': !row.hasOpened
                }"
            ></tr>
        </table>
    </div>

    <mat-paginator class="mt-auto" [ngClass]="{'!hidden': !dataSource.data.length}"
                   (page)="handlePageEvent($event)"
                   [length]="paginator.length"
                   [pageSize]="paginator.pageSize"
                   [pageSizeOptions]="[25, 50, 100]"
                   [pageIndex]="paginator.pageIndex"
                   aria-label="Select page of periodic elements" color="accent" [showFirstLastButtons]="true">
    </mat-paginator>

    @if (loading()) {
        <div class="absolute inset-0 flex items-center justify-center pointer-events-none">
            <mat-spinner color="accent"></mat-spinner>
        </div>
    }
</div>
