import { Component, effect, inject, input, InputSignal, signal, WritableSignal, } from "@angular/core";
import { DatePipe, NgClass, NgOptimizedImage } from "@angular/common";
import { MatButton, MatIconButton } from "@angular/material/button";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatSortModule, Sort } from "@angular/material/sort";
import { ActivatedRoute, Params, Router, RouterLink } from "@angular/router";
import {
    CaseIncidentResponse,
    Incident,
    IncidentCaseQueryParams,
    PaginatorSetting,
    TableSetting,
    TableSettingRequest,
} from "../../api/models/api.model";
import { MatDialog } from "@angular/material/dialog";
import { AddOfferComponent } from "../../partials/components/dialogs/add-offer/add-offer.component";
import { debounceTime, distinctUntilChanged, filter, switchMap, } from "rxjs/operators";
import { retry, take } from "rxjs";
import { ApiService } from "../../api/services/api.service";
import { Pagination } from "../../api/models/pagination.model";
import { MatSlideToggle, MatSlideToggleChange, } from "@angular/material/slide-toggle";
import { FormsModule, NonNullableFormBuilder, ReactiveFormsModule } from "@angular/forms";
import { CopyButtonComponent } from "../../partials/components/copy-button/copy-button.component";
import { SearchService } from "../../partials/services/search.service";
import { takeUntilDestroyed, toSignal } from "@angular/core/rxjs-interop";
import { AddInvoiceComponent } from "../../partials/components/dialogs/add-invoice/add-invoice.component";
import { MatIcon } from "@angular/material/icon";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { CropWithDotsCenterPipe } from "../../partials/pipes/crop-with-dots-center.pipe";
import { MatTooltip } from "@angular/material/tooltip";
import { MatCheckbox, MatCheckboxChange } from "@angular/material/checkbox";
import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray, } from "@angular/cdk/drag-drop";
import { CdkScrollable } from "@angular/cdk/scrolling";
import { Store } from "@ngrx/store";
import { ProfileSelectors, ProfileStoreState, Role } from "../../core/store/profile";
import { TelegramUserLinkComponent } from "../../partials/components/telegram-user-link/telegram-user-link.component";
import { OfferUpgradeComponent } from "../../partials/components/dialogs/offer-upgrade/offer-upgrade.component";
import { MatCard, MatCardContent } from "@angular/material/card";
import {
    MatDatepickerToggle,
    MatDateRangeInput,
    MatDateRangePicker,
    MatEndDate,
    MatStartDate
} from "@angular/material/datepicker";
import { MatError, MatFormField, MatLabel, MatSuffix } from "@angular/material/form-field";
import { dateRangeValidator } from "../../partials/validators/validators";
import moment, { Moment } from "moment/moment";

// Настройки таблицы которые сохраняются в API
export interface RequestTableSettings {
    allDisplayedColumns: string[];
    filteredDisplayedColumns: string[];
}

// Имя таблицы необходимо для сохранения настроек в API
export const requestTableName = "RequestTable";

@Component({
    selector: "app-requests",
    standalone: true,
    imports: [
        MatPaginator,
        MatProgressSpinner,
        MatTableModule,
        MatSortModule,
        NgClass,
        NgOptimizedImage,
        RouterLink,
        MatSlideToggle,
        FormsModule,
        CopyButtonComponent,
        MatIcon,
        MatIconButton,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        DatePipe,
        CropWithDotsCenterPipe,
        MatTooltip,
        MatCheckbox,
        CdkDropList,
        CdkDrag,
        CdkScrollable,
        CdkDragHandle,
        TelegramUserLinkComponent,
        MatButton,
        MatCard,
        MatCardContent,
        MatSortModule,
        MatDateRangeInput,
        MatDateRangePicker,
        MatDatepickerToggle,
        MatEndDate,
        MatError,
        MatFormField,
        MatLabel,
        MatStartDate,
        MatSuffix,
        ReactiveFormsModule,
    ],
    templateUrl: "./requests.component.html",
    styleUrl: "./requests.component.scss",
})
export default class RequestsComponent {
    initCaseIncidentResponse: InputSignal<Pagination<CaseIncidentResponse>> =
        input.required<Pagination<CaseIncidentResponse>>();

    adminRole = input.required<Role | undefined>();

    private readonly _matDialog: MatDialog = inject(MatDialog);

    private readonly _apiService: ApiService = inject(ApiService);

    private readonly _searchService: SearchService = inject(SearchService);

    private readonly router: Router = inject(Router);

    private readonly _profileStore: Store<{ profile: ProfileStoreState }> =
        inject<Store<{ profile: ProfileStoreState }>>(Store);

    user = toSignal(
        this._profileStore.select(ProfileSelectors.selectProfileInformation),
    );

    readonly dataSource: MatTableDataSource<CaseIncidentResponse> =
        new MatTableDataSource<CaseIncidentResponse>([]);

    readonly loadedTableSettings: InputSignal<
        TableSetting<RequestTableSettings> | undefined
    > = input<TableSetting<RequestTableSettings> | undefined>();

    allDisplayedColumns: string[] = [
        "userId",
        "id",
        "telegramUsername",
        "country",
        "victimType",
        "scamTypeId",
        "telegramUserId",
        "submittedAt",
        "updatedAt",
        "phone",
        "email",
        "kyc",
        "caseAmount",
        "leadStatus",
        "paymentDue",
        "totalCost",
        "numberOfInvoices",
        "referralPermission",
        "actions",
    ];

    filteredDisplayedColumns: string[] = [];

    get menuDisplayedColumns(): string[] {
        const filteredColumns: string[] = [];
        switch (this.adminRole()) {
            case "COMPLIANCE": {
                filteredColumns.push(
                    'telegramUsername',
                    'telegramUserId',
                    'phone',
                    "email",
                    'numberOfInvoices',
                    'paymentDue',
                    'totalCost',
                    'actions',
                );
                break;
            }
        }
        return this.allDisplayedColumns.filter(
            (column) => !filteredColumns.includes(column),
        );
    }

    get displayedColumns() {
        const filteredColumns = [...this.filteredDisplayedColumns];
        switch (this.adminRole()) {
            case "COMPLIANCE": {
                filteredColumns.push(
                    'telegramUsername',
                    'telegramUserId',
                    'phone',
                    "email",
                    'numberOfInvoices',
                    'paymentDue',
                    'totalCost',
                    'actions',
                );
                break;
            }
        }
        return this.allDisplayedColumns.filter(
            (column) => !filteredColumns.includes(column),
        );
    }

    paginator: PaginatorSetting = {
        pageSize: 25,
        pageIndex: 0,
        length: 0,
    };

    private _sort: Sort = { active: '', direction: '' };
    private readonly _findBy: WritableSignal<string> = signal("");
    readonly referralCode: WritableSignal<string | null> = signal(null);
    readonly referralUsername: WritableSignal<string | null> = signal(null);

    get _queryParams() {
        const { pageSize, pageIndex } = this.paginator;
        const { active: sortBy, direction } = this._sort;
        const findBy = this._findBy();
        const status = this.filterStatus();
        const queryParams: IncidentCaseQueryParams = {
            size: pageSize,
            page: pageIndex,
        };
        if (findBy) {
            queryParams.findBy = findBy;
        }
        if (status) {
            queryParams.status = status;
        }
        if (sortBy && direction !== '') {
            queryParams.sortBy = sortBy;
            queryParams.sortOrder = direction === 'asc' ? 1 : -1;
        }
        return queryParams;
    }

    readonly filterStatus = signal<null | 'LEAD' | 'NEW' | 'OFFER' | 'INITIAL_INVOICE' | 'OFFER_EXPIRED' | 'CUSTOMER' | 'DONE'>(null);

    readonly loading: WritableSignal<boolean> = signal(false);

    private readonly _route: ActivatedRoute = inject(ActivatedRoute);

    private readonly _fb: NonNullableFormBuilder = inject(NonNullableFormBuilder);

    readonly rangePickerCSVForm = this._fb.group({
        from: this._fb.control<Moment | null>(null),
        to: this._fb.control<Moment | null>(null),
    }, { validators: dateRangeValidator });

    constructor() {
        effect(
            () => {
                const { content, totalElements, number, size } =
                    this.initCaseIncidentResponse();
                this.dataSource.data = content;

                this.paginator.pageSize = size;
                this.paginator.pageIndex = number;
                this.paginator.length = totalElements;
            },
            { allowSignalWrites: true },
        );

        effect(
            () => {
                const loadedTableSettings = this.loadedTableSettings();
                if (loadedTableSettings) {
                    const {
                        data: { allDisplayedColumns, filteredDisplayedColumns },
                    } = loadedTableSettings;

                    let compair = null;
                    if (this.allDisplayedColumns.length !== allDisplayedColumns.length) {
                        compair = true;
                    }
                    this.allDisplayedColumns.forEach((item: string) => {
                        if (!allDisplayedColumns.includes(item)) {
                            compair = true;
                        }
                    });
                    if (compair) {
                        this.setColumn();
                    } else {
                        this.allDisplayedColumns = allDisplayedColumns;
                    }
                    this.filteredDisplayedColumns = filteredDisplayedColumns;
                }
            },
            { allowSignalWrites: true },
        );

        effect(() => {
            this.filterStatus();
            this.paginator.pageIndex = 0;
            this._loadCaseIncidentResponse();
        }, { allowSignalWrites: true });

        this._searchService.searchControl.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged(), takeUntilDestroyed())
            .subscribe({
                next: (term) => {
                    this._findBy.set(term);
                    this.paginator.pageIndex = 0;
                    this._loadCaseIncidentResponse();
                },
            });

        this._route.queryParams.pipe(takeUntilDestroyed()).subscribe({
            next: (queryParams: Params) => {
                const findBy = queryParams["findBy"];
                if (findBy) {
                    this._searchService.searchControl.patchValue(findBy);
                }
                const referralCode = queryParams["referralCode"];
                if (referralCode) {
                    this.referralCode.set(referralCode);
                } else {
                    this.referralCode.set(null);
                    this._loadCaseIncidentResponse();
                }
                const telegramUsername = queryParams["telegramUsername"];
                if (telegramUsername) {
                    this.referralUsername.set(telegramUsername);
                } else {
                    this.referralUsername.set(null);
                }
            },
        });
    }

    private setColumn(): void {
        this._saveTableSettings();
    }

    clearFilter(): void {
        this.referralCode.set(null);
        this.referralUsername.set(null);
        this.router.navigate([]);
    }

    addOffer(incident: Incident): void {
        this._matDialog
            .open(AddOfferComponent, {
                data: { incident },
                autoFocus: true,
                width: "800px",
            })
            .afterClosed()
            .pipe(
                filter(Boolean),
                switchMap(() => this._apiService.getIncidentCases()),
                take(1),
            )
            .subscribe({
                next: ({ content }) => {
                    this.dataSource.data = content;
                },
            });
    }

    addInvoice(incident: Incident): void {
        this._matDialog
            .open(AddInvoiceComponent, {
                data: { incident },
                autoFocus: true,
            })
            .afterClosed()
            .pipe(
                filter(Boolean),
                switchMap(() => this._apiService.getIncidentCases()),
                take(1),
            )
            .subscribe({
                next: ({ content }) => {
                    this.dataSource.data = content;
                },
            });
    }

    canCreateReferrals(
        caseIncidentResponse: CaseIncidentResponse,
        { checked }: MatSlideToggleChange,
    ): void {
        this._apiService
            .putCanCreateReferrals(caseIncidentResponse.userId, checked)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._loadCaseIncidentResponse();
                },
                error: () => {
                    caseIncidentResponse.canCreateReferral = false;
                },
            });
    }

    handlePageEvent(e: PageEvent) {
        this.paginator.pageSize = e.pageSize;
        this.paginator.pageIndex = e.pageIndex;
        this._loadCaseIncidentResponse();
    }

    cancelCase(caseIncidentResponse: CaseIncidentResponse): void {
        const { id } = caseIncidentResponse;
        this._apiService
            .putIncidentStatus(id, { incidentStatus: "ADMIN_CANCELED" })
            .pipe(retry(3), take(1))
            .subscribe({
                next: () => {
                    caseIncidentResponse.incidentStatus = "ADMIN_CANCELED";
                },
            });
    }

    toSuccess(caseIncidentResponse: CaseIncidentResponse): void {
        const { id } = caseIncidentResponse;
        this._apiService
            .putIncidentStatus(id, { incidentStatus: "SUCCESS" })
            .pipe(retry(3), take(1))
            .subscribe({
                next: () => {
                    caseIncidentResponse.incidentStatus = "SUCCESS";
                },
            });
    }

    toDone(caseIncidentResponse: CaseIncidentResponse): void {
        const { id } = caseIncidentResponse;
        this._apiService
            .putIncidentStatus(id, { incidentStatus: "DONE" })
            .pipe(retry(3), take(1))
            .subscribe({
                next: () => {
                    caseIncidentResponse.incidentStatus = "DONE";
                },
            });
    }

    private _loadCaseIncidentResponse(): void {
        this.loading.set(true);
        this._apiService
            .getIncidentCases(this._queryParams)
            .pipe(retry(3), take(1))
            .subscribe({
                next: ({ content, totalElements, number, size }) => {
                    this.dataSource.data = content;

                    this.paginator.pageSize = size;
                    this.paginator.pageIndex = number;
                    this.paginator.length = totalElements;
                    this.loading.set(false);
                },
                error: () => {
                    this.loading.set(true);
                },
            });
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.allDisplayedColumns,
            event.previousIndex,
            event.currentIndex,
        );
        this._saveTableSettings();
    }

    upgradeOffer({
                     id: incidentId,
                     actualOfferId: offerId,
                 }: CaseIncidentResponse): void {
        this._apiService
            .getOfferByIncidentIdAndOfferId(incidentId, offerId)
            .pipe(
                switchMap((offer) =>
                    this._matDialog
                        .open(OfferUpgradeComponent, {
                            data: { offer },
                        })
                        .afterClosed(),
                ),
            )
            .pipe(filter(Boolean), take(1))
            .subscribe({
                next: () => {
                    this._loadCaseIncidentResponse();
                },
            });
    }

    handlerChangeDisplayColumnCheckbox(
        { checked }: MatCheckboxChange,
        displayedColumn: string,
    ): void {
        if (checked) {
            this.filteredDisplayedColumns = this.filteredDisplayedColumns.filter(
                (c) => c !== displayedColumn,
            );
        } else {
            this.filteredDisplayedColumns.push(displayedColumn);
        }
        this._saveTableSettings();
    }

    private _saveTableSettings(): void {
        const user = this.user();
        if (!user) return;
        const { uuid } = user;
        const body: TableSettingRequest = {
            data: {
                allDisplayedColumns: this.allDisplayedColumns,
                filteredDisplayedColumns: this.filteredDisplayedColumns,
            },
            tableName: requestTableName,
            userId: uuid,
        };
        this._apiService
            .createOrUpdateTableSetting<RequestTableSettings>(body)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    console.log(`${ requestTableName } settings saved!`);
                },
            });
    }

    handleSort(sort: Sort) {
        this._sort = sort;
        this._loadCaseIncidentResponse();
    }

    downloadCSV(): void {
        const { from, to } = this.rangePickerCSVForm.getRawValue();

        const queryParams: Partial<{ from: number, to: number }> = {};

        if (from && to) {
            queryParams.from = moment(from).startOf('day').valueOf();
            queryParams.to = moment(to).endOf('day').valueOf();
        }

        this._apiService.getExportedCases(queryParams).pipe(take(1)).subscribe({
            next: (file: Blob) => {
                if (typeof file !== "boolean") {
                    const downloadLink = document.createElement("a");
                    downloadLink.href = window.URL.createObjectURL(file);
                    downloadLink.setAttribute("download", ('Cases'));
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    return false;
                }
                return true;
            }
        });
    }
}
